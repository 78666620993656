.acdum {
  border-radius: 5px;
  /* width: 48vw; */
  width: 700px;
  height: 350px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.acdum-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  background-color: #f4f4f4;
  font-size: 16px;
}

.acdum-main {
  flex-grow: 1;
  width: 85%;
  margin-left: 4%;
  display: flex;
  flex-direction: column;
}

.acdum-user-table-pretext {
  margin-top: 20px;
  font-size: 13px;
}

.acdum-user-table {
  display: grid;
  margin-top: 5px;
  grid-template-columns: 40% 30% 30%;
  grid-template-rows: 30px 30px;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
}

.acdum-ut-od-item {
  display: flex;
  align-items: center;
}

.acdum-ut-od-1,
.acdum-ut-od-2,
.acdum-ut-od-3 {
  background-color: #e3e3e3;
}

.acdum-ut-od-1,
.acdum-ut-od-4 {
  padding-left: 14px;
}

.acdum-ut-od-3,
.acdum-ut-od-6 {
  justify-content: center;
}

.acdum-warning-text {
  margin-top: 20px;
  background: rgba(255, 238, 191, 1);
  font-size: 12px;
  border-radius: 4px;
  padding: 5px;
}

.acdum-confirm-email-container {
  margin-top: auto;
  margin-bottom: 17px;
}

.acdum-confirm-email-text {
  font-weight: 700;
  font-size: 12px;
}

.acdum-email-confirm-input {
  margin-top: 3px;
  width: 100%;
  padding: 4px;
  padding-left: 8px;
  border: 1px solid #606060;
  border-radius: 3px;
}

.acdum-email-confirm-input::placeholder {
  color: #a8a8a8;
}

.acdum-footer {
  margin-top: auto;
  height: 40px;
  border-top: 1px solid rgba(217, 217, 217, 1);
  display: flex;
  align-items: center;
}

.acdum-footer-delete-button {
  margin-left: 10px;
  font-size: 0.65rem;
  padding: 0.25rem 0.6rem;
}

.acdum-footer-cancel-button {
  margin-left: 20px;
  font-size: 0.65rem;
  color: #848484;
  border: none;
  border-bottom: 2px solid #848484;
  background: none;
}

.acdum-footer-cancel-button:hover {
  cursor: pointer;
}
