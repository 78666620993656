$page_background_color: #f1f4f6;

$signIn_button_color: #4c7ede;

$add_button_color: #36c74c;
$edit_button_color: #3f6ad8;
$delete_button_color: #dc3232;

$text_color: #000;
$text_color_weak: #555f78;
$text_color_faded: #8e8e8e;
$text_color_contrast: #fff;

$table_row_alternate_color: #f7f7f7;

$input_background_color_default: #f6f6f5;
