.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 270px;
  /* background: rgba(30, 209, 30, 0.3); */
  background: white;
  min-height: 100vh;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}

.sidebar-header {
  /* background: #f3f6f9; */
  font-family: arial, sans-serif;
  font-size: 20px;
  padding: 30px 15px 15px 25px;
  font-weight: 700;
  letter-spacing: 0.12rem;
  line-height: 1.5;
}

.sidebar-menu {
  flex-grow: 1;
  margin-top: 25px;
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  flex-direction: column;
  row-gap: 10px;
}

.sidebar-menu-item {
  display: flex;
  font-family: 'Mulish', sans-serif;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  font-size: 17px;
  column-gap: 29px;
}

.sidebar-menu-item.active {
  background: #f1f4f6;
}

.sidebar-menu-item-icon {
  width: 17px;
}

.sidebar-menu-item-logout {
  // align-self: flex-end;
  margin-top: auto;
  margin-bottom: 3%;
  background-color: transparent;
}
