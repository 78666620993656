.card-table-photos-image-preview {
  width: 64px;
  border-radius: 3px;
  vertical-align: middle;
  margin: 7px 0;
}

.card-table-photos-delete-button {
  margin-left: 10px;
}
