@use '../styles/colors.scss';

* {
  box-sizing: border-box;
  color: colors.$text_color;
  font-family: 'metropolis';
}

body {
  margin: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 25px;
}

.text-center {
  text-align: center;
}

input {
  background-color: colors.$input_background_color_default;
  border: none;
  padding-left: 0.3rem;
}

textarea {
  padding-left: 0.3rem;
}

input::placeholder {
  color: colors.$text_color_faded;
}

button {
  border: none;
}

table {
  border-collapse: collapse;
}

a:link,
a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
}

/* Removing arrows off number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

button:hover {
  cursor: pointer;
}

.page {
  min-width: 100vw;
  min-height: 100vh;
  background-color: colors.$page_background_color;
}

.panel-page {
  padding-left: 270px;
  min-height: 100vh;
  background: #f1f4f6;
  display: flex;
  justify-content: center;
}

.panel-page-inner {
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1100px;
  grid-row-gap: 30px;
  row-gap: 30px;
  padding: 25px;
}

.card {
  border-radius: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

//Table card styles
.card-table-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-collapse: collapse;
}

.card-table-header {
  color: rgba(13, 27, 62, 0.7);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e9f2;
  font-weight: 700;
  text-transform: uppercase;
  font-weight: 0.88rem;
  padding-left: 20px;
  height: 3.5rem;
}

.card-table-row:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.03);
}

.card-table-cell {
  padding: 0.55rem;
}

.card-table-header-cell {
  font-size: 16px;
}

.card-table-footer {
  border-top: 1px solid #e7e9f2;
  display: flex;
  padding: 10px;
}

.card-table-page-buttons {
  display: flex;
  margin-left: auto;
  grid-column-gap: 3px;
  -webkit-column-gap: 3px;
  column-gap: 3px;
}

.table-button {
  text-align: center;

  cursor: pointer;
  border: 0 solid transparent;
  font-size: 0.9rem;
  border-radius: 0.2rem;
  padding: 0.4rem 0.7rem;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
}

.details-button {
  background-color: colors.$edit_button_color;
  color: colors.$text_color_contrast;
}

.delete-button {
  background-color: colors.$delete_button_color;
  color: colors.$text_color_contrast;
}

.edit-button {
  background-color: colors.$edit_button_color;
  color: colors.$text_color_contrast;
}

.add-button {
  background-color: colors.$add_button_color;
  color: colors.$text_color_contrast;
}

.table-header-add-button {
  margin-left: auto;
  margin-right: 2%;
}
